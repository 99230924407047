.ReactModal__Body--open {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

html {
  scroll-behavior: smooth;
}

button {
  cursor: pointer;
  outline: 0 !important;
}

.glass-hover {
  position: relative;
  overflow: hidden;
}

.glass-hover:after {
  background: #fff;
  content: '';
  height: 155px;
  left: -75px;
  opacity: 0.2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  /* z-index: -10; */
}

.glass-hover:hover:after {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.smaller-hover {
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.smaller-hover:hover {
  transform: scale(0.95);
}

.bigger-hover {
  transition: ease-in-out 0.3s;
}

.bigger-hover:hover {
  transform: scale(1.05);
}

.fadeIn-effect {
  animation: fadeIn ease-in-out 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut-effect {
  animation: fadeOut ease-in-out 0.5s;
}

@keyframes bounceInDown {

  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0) translate(-50%, -50%);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0) translate(-50%, -50%);
  }

  75% {
    transform: translate3d(0, -10px, 0) translate(-50%, -50%);
  }

  90% {
    transform: translate3d(0, 5px, 0) translate(-50%, -50%);
  }

  to {
    transform: translate3d(0, 0, 0) translate(-50%, -50%);
  }
}

.bounceInDown {
  animation: bounceInDown ease-in-out 0.8s;
}

.border-box-box-sizing {
  box-sizing: border-box;
}

.container {
  width: 1140px !important;
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}

@media screen and (min-width: 300px) and (max-width: 992px) {
  .container {
    width: 100% !important;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;
  }
}

@media screen and (min-width: 300px) and (max-width: 620px) {
  .modal-responsive-custom {
    max-height: 100vh !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    transform: unset !important;
    height: 100%;
  }
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'),
    url('./static/fonts/OpenSans/OpenSans-Regular.ttf') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
    url('./static/fonts/Poppins/Poppins-Regular.ttf') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url('./static/fonts/Roboto/Roboto-Regular.ttf') format('woff2');
  font-display: swap;
}